/*eslint no-unused-vars: "off"*/

import React, { useReducer, useState, useEffect, useRef } from 'react';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Modal, Input, Container } from '@material-ui/core';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

// @see: https://material-ui.com/components/pickers/
import Grid from '@material-ui/core/Grid';
// import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  DatePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';



import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

// s4n 
import { useIntl } from "gatsby-plugin-intl"
import Preloader from './../../s4n/Preloader'
import classNames from 'classnames';
import Yup from './../../s4n/Yup';
import FormHelper from './../../s4n/Form/standalone'
import { colors as ConfigSharedColors } from './../../s4n/Config/Shared'
// import { DisplayFormikState } from "./../formikHelper"

import GeneralUtils from './../../../utils/common'
import { connect } from 'react-redux'
import { toggleModalBooking } from './../../../s4n/state/actions/modal'
import { bindOnClickOnKeyDown } from './../../s4n/Accessibility'

// https://github.com/turo/react-fixed-bottom
// import { FixedBottom } from 'react-fixed-bottom'

import Quantity from './../../s4n/Quantity'


// const BOOKING_URL = `https://widget.bsengine.pl/start?hash=eyJpdiI6Im40MlFpVXd4OXpQdWFwKzBXU0x5emc9PSIsInZhbHVlIjoidWpLcVJrXC9cL1QxNXV3NnJxakxrMlJnPT0iLCJtYWMiOiI5YzhmYTAwODUxMGYwZDA4MTE3M2I0YjA1MTViOTJjNjk2ZjZkYTZhNjM4NTk4YTRhODY2YzA1ODI3MTk0NDk2In0`;


// @see: https://stackoverflow.com/questions/49535551/change-secondary-and-primary-colors-in-material-ui
// Or Create your Own theme:
const theme = createTheme({
  palette: {
    primary: {
      main: ConfigSharedColors.primary
    },
    secondary: {
      main: ConfigSharedColors.secondary
    }
  }
});

const styles = theme => ({
  root: {
    color: "#FFFFFF"
  },

  datePicker: {
    // marginTop: `8px`,
    // marginBottom: `8px`,
    // minWidth: `5rem`,
    // [theme.breakpoints.up('md')]: {
    //   minWidth: `7rem`,
    // },
    // [theme.breakpoints.up('lg')]: {
    //   minWidth: `10rem`,
    // },
    paddingLeft: `0.5rem`,
    color: `#fff`,
    input: {
      color: `#fff`,
      padding: `0.5rem 0`
    },
    backgroundColor: `#fff`,
  },

  select: {
    // marginTop: `8px`,
    // marginBottom: `8px`,
    // minWidth: `5rem`,
    // [theme.breakpoints.up('md')]: {
    //   minWidth: `7rem`,
    // },
    // [theme.breakpoints.up('lg')]: {
    //   minWidth: `10rem`,
    // },
    paddingLeft: `0.5rem`,
    color: `#fff`,
    input: {
      color: `#fff`,
      padding: `0.5rem 0`
    },
    // backgroundColor: `#fff`,
  },

  formButtonClass: {
    // paddingLeft: `1rem`,
    // paddingRight: `1rem`,
    // height: `2.4rem`,
    // fontSize: `0.75rem`,
    // borderRadius: `0`,
    // backgroundColor: ConfigSharedColors.primary,
    // [`:hover`]: {
    //   backgroundColor: ConfigSharedColors.primary,
    // }
  },
});







const FormStateless = (props) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  const { 
    nid: nodeId,
    classes, 
    className,
    context,
    toggleModalBooking: propsToggleModalBooking,
    isModalBookingOpened: propsIsModalBookingOpened,
  } = props;

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = new Date(tomorrow);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);

  // @see: https://material-ui.com/components/pickers/
  const [selectedDateArrival, setSelectedDateArrival] = useState(tomorrow);
  const [selectedDateDeparture, setSelectedDateDeparture] = useState(dayAfterTomorrow);
  const [selectedAdult, setSelectedAdult] = useState(2);

  function handleDateChangeArrival(date) {
    setSelectedDateArrival(date);
  }
  function handleDateChangeDeparture(date) {
    setSelectedDateDeparture(date);
  }
  function handleAdultChange(event) {
    setSelectedAdult(event.target.value);
  }

  const [formStatus, setFormStatus] = useState({
    success: null,
    class: null,
    message: null,
  });

  const [state, setState] = useState({
    loading: false,
    error: false,
    formSchema: null,
  });

  const formSchema = {}

  let initialValues = {
    subject: 'Rezerwacja ze strony pokojewstarejkarczmie.pl',
    name: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  }

  // require to provide at least one contact field e-mail or phone 
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    message: Yup.string().required(),
  })

  // Set data for FormHelper START
  // set form ID!!!
  initialValues.webform_id = 'booking_s4n';
  // oFormHelper.formEndpoint = '';

  const oFormHelper = new FormHelper(intl);
  
  oFormHelper.t = t;
  oFormHelper.setFormStatus = setFormStatus;
  oFormHelper.state = state;
  oFormHelper.setState = setState;
  // Set data for FormHelper STOP

  /**
   * @see: https://reactjs.org/docs/hooks-effect.html
   * Same as componentDidMount() hook because we pass an empty array [] as second argument,
   * this argument watches for changes in passed arguments
   * In componentDidMount we have access to window object elsewhere not always!!!
   */
  // useEffect(() => {
  //   // Form schema does not contain markup elements!
  //   oFormHelper.getFormSchema(initialValues.webform_id);
  // }, []);


  const handleOpenBookingModal = (props) => {
    // props.toggleModalBooking(props.isModalBookingOpened); // this modifies redux store state
    propsToggleModalBooking(props.isModalBookingOpened); // this modifies redux store state
  }

  const handleCloseBookingModal = () => {
    propsToggleModalBooking(props.isModalBookingOpened); // this modifies redux store state
  };


  // const datePickerArrivalRef = useRef(null);
  // const datePickerDepartureRef = useRef(null);
  const datePickerArrivalRefWrapperElement = useRef(null);
  const datePickerDepartureRefWrapperElement = useRef(null);
  // const datePickerDepartureRefWrapperElement = useRef(null);

  const initialStateDateChangeOpenStatus = {
    isDateChangeArrivalOpen: false,
    isDateChangeDepartureOpen: false,
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case `setOpenStateArrival`: 
        return {
          ...state,
          isDateChangeArrivalOpen: action.payload,
        };
      case `setOpenStateDeparture`:
        return {
          ...state,
          isDateChangeDepartureOpen: action.payload,
        };
      default: 
        throw new Error('Unexpected action');
    }
  };
  const [dateChangeOpenState, dispatch] = useReducer(reducer, initialStateDateChangeOpenStatus);



  // @see: https://stackoverflow.com/questions/49899788/how-to-render-a-material-ui-input-control-instead-a-textfield-for-materials-ui-d
  const RenderCustomTextFieldComponent = props => {
// console.log(props)
    return (
      // <Container
      //   {...props}
      // >
      // <Input>
        <div
          {...props}

          type="text"
          onClick={props.onClick}
          id={props.id}
          value={props.value}
          disabled={props.disabled}
          className="position-relative"

          ref={props.inputRef}
          inputRef={props.inputRef}
          inputProps={props.inputProps}
          popoverProps={props.PopoverProps}

        >
          {props.value.split(` `).map((datePart, index) => {
            return <span key={`renderCustomTextFieldComponent-${index}`} className={`date-part date-part-${index}`}>{datePart}</span>
          })}
          {props.children}
        </div>
      // </Input>
      // </Container>
    )

    // return (
    //   <Input
    //     type={'text'}
    //     {...props}
    //     id={props.id}
    //     value={props.value}
    //     // onChange={this.handleChange}
    //     onChange={props.onChange}
    //     onClick={props.onClick}
    //     disabled={props.disabled}
    //   />
    // );

  }

  const _RenderCustomTextFieldComponent = (props) => {

    const {
      InputProps,
      disabled,
      error,
      helperText,
      onChange,
      value,
    } = props

    return (
      <TextField
        InputProps={InputProps}
        disabled={disabled}
        error={error}
        helperText={helperText}
        onChange={onChange}
        value={value}
      />
    )
  };

  return (
    
      <MuiThemeProvider theme={theme}>
        
          {oFormHelper.state.loading ? // or state.loading this is a reference
            <Preloader /> :
            <Formik
              initialValues={initialValues}
              onSubmit={oFormHelper.onSubmit}
              validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  status,
                  touched,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  setFieldValue,
                } = props;

                // console.log(status)

                const formStatusClass = classNames({
                  // "mb-0": true,
                  "alert": true,
                  "d-none": null === formStatus.class,
                  [`alert-${formStatus.class}`]: true,
                });

                const formButtonClass = classNames({
                  "btn btn-primary": true,
                  "green": formStatus && formStatus.success,
                });

                return (
                  // <FixedBottom offset={56}>
                    <Form 
                        onSubmit={handleSubmit} 
                        // className={initialValues.webform_id}
                        className={classNames(
                          initialValues.webform_id,
                          className,
                        )}
                    >
                      {nodeId === Number(process.env.SOFT4NET_HOME_PAGE_DRUPAL_ID) && 
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <Grid container justifyContent="space-around">
                            {/* <strong>{intl.locale === `en` ? `Online booking` : `Rezerwacja online`}</strong> */}
                            <div ref={datePickerArrivalRefWrapperElement} className="d-flex">
                              {/* <span>{intl.locale === `en` ? `Arrival` : `Przyjazd`}</span> */}
                              <KeyboardDatePicker
                                // ref={datePickerArrivalRef}
                                // minDate={tomorrow}
                                minDate={today}
                                disableToolbar
                                variant="inline"
                                // format="L"
                                format="DD MMM 'YY"
                                margin="normal"
                                id="date-picker-inline"
                                // labelPlacement="start"
                                // label={intl.locale === `en` ? `Arrival` : `Przyjazd`}
                                value={selectedDateArrival}
                                onChange={handleDateChangeArrival}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}

                                className={classes.datePicker}
                                InputProps={{
                                  disableUnderline: true
                                }}
                                TextFieldComponent={RenderCustomTextFieldComponent}

                                // @see: https://github.com/mui-org/material-ui-pickers/issues/102
                                // onClick={() => datePickerArrivalRef.current.togglePicker()}
                                
                                // @see: https://stackoverflow.com/questions/61238028/how-do-i-spawn-my-date-picker-ui-upon-first-clicking-on-the-date-text-field
                                onClick={() => dispatch({ type: `setOpenStateArrival`, payload: true })}
                                // onClose={() => dispatch({ type: `setOpenStateArrival`, payload: false })}
                                KeyboardButtonProps={{
                                  onFocus: e => {
                                    dispatch({ type: `setOpenStateArrival`, payload: true });
                                  }
                                }}
                                InputProps={{
                                  onFocus: () => {
                                    dispatch({ type: `setOpenStateArrival`, payload: true });
                                  }
                                }}
                                PopoverProps={{
                                  /**
                                   * A HTML element, or a function that returns it.
                                   * It's used to set the position of the popover.
                                   * anchorEl?: null | Element | ((element: Element) => Element);
                                   */
                                  anchorEl: datePickerArrivalRefWrapperElement.current,
                                  disableRestoreFocus: true,
                                  onClose: () => {
                                    dispatch({ type: `setOpenStateArrival`, payload: false });
                                  },
                                }}
                                open={dateChangeOpenState.isDateChangeArrivalOpen}

                                autoOk // false | Auto accept date on selection
                                // inputVariant="outlined"
                                // InputAdornmentProps={{ position: "start" }}

                              />
                            </div>
                            <div ref={datePickerDepartureRefWrapperElement} className="d-flex">
                              {/* <span>{intl.locale === `en` ? `Departure` : `Odjazd`}</span> */}
                              <KeyboardDatePicker
                                // ref={datePickerDepartureRef}
                                minDate={selectedDateArrival}
                                disableToolbar
                                variant="inline"
                                // format="L"
                                format="DD MMM 'YY"
                                margin="normal"
                                id="date-picker-inline"
                                // labelPlacement="start"
                                // label={intl.locale === `en` ? `Departure` : `Odjazd`}
                                value={selectedDateDeparture}
                                onChange={handleDateChangeDeparture}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}

                                className={classes.datePicker}
                                InputProps={{
                                  disableUnderline: true
                                }}
                                TextFieldComponent={RenderCustomTextFieldComponent}

                                // @see: https://github.com/mui-org/material-ui-pickers/issues/102
                                // onClick={() => datePickerArrivalRef.current.togglePicker()}
                                
                                // @see: https://stackoverflow.com/questions/61238028/how-do-i-spawn-my-date-picker-ui-upon-first-clicking-on-the-date-text-field
                                onClick={() => dispatch({ type: `setOpenStateDeparture`, payload: true })}
                                KeyboardButtonProps={{
                                  onFocus: e => {
                                    dispatch({ type: `setOpenStateDeparture`, payload: true });
                                  }
                                }}
                                InputProps={{
                                  onFocus: () => {
                                    dispatch({ type: `setOpenStateDeparture`, payload: true });
                                  }
                                }}
                                PopoverProps={{
                                  /**
                                   * A HTML element, or a function that returns it.
                                   * It's used to set the position of the popover.
                                   * anchorEl?: null | Element | ((element: Element) => Element);
                                   */
                                  anchorEl: datePickerDepartureRefWrapperElement.current,
                                  disableRestoreFocus: true,
                                  onClose: () => {
                                    dispatch({ type: `setOpenStateDeparture`, payload: false });
                                  },
                                }}
                                open={dateChangeOpenState.isDateChangeDepartureOpen}

                                autoOk // false | Auto accept date on selection
                                // inputVariant="outlined"
                                // InputAdornmentProps={{ position: "start" }}

                              />
                            </div>













                            <div 
                              // ref={datePickerDepartureRefWrapperElement} 
                              className="d-flex select-adult"
                            >
                              {/* <span>{intl.locale === `en` ? `Adults` : `Dorosłych`}</span> */}

                              <FormControl 
                                // variant="outlined" 
                                // className={classes.formControl}
                                className="select-adult"
                              >
                                <InputLabel htmlFor="outlined-age-native-simple">{intl.locale === `en` ? `Adults` : `Dorosłych`}</InputLabel>
                                <Quantity quantity={parseInt(selectedAdult)} onQuantityModify={setSelectedAdult} />
                              </FormControl>

                              {/* <Quantity quantity={parseInt(selectedAdult)} onQuantityModify={setSelectedAdult} /> */}

                              {/* <Pagination 
                                count={20} 
                                defaultPage={2} 
                                siblingCount={0} 
                                
                                boundaryCount={1}
                                siblingCount={0}
                              /> */}

                              {/* {false &&
                              <FormControl 
                                // variant="outlined" 
                                // className={classes.formControl}
                                className="select-adult"
                              >
                                <InputLabel htmlFor="outlined-age-native-simple">Dorośli</InputLabel>
                                <Select
                                  native
                                  value={selectedAdult}
                                  onChange={handleAdultChange}
                                  label="Dorośli"
                                  className={classes.select}
                                  inputProps={{
                                    name: 'dorosli',
                                    id: 'outlined-dorosli-native-simple',
                                    // disableUnderline: true,
                                  }}

                                  // variant="inline"
                                  margin="normal"

                                >
                                  <option aria-label="None" value="" />
                                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(v => 
                                    <option value={v}>{v}</option>
                                  )}
                                </Select>
                              </FormControl>
                              } */}

                            </div>












                            <div className="d-flex">
                              <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary"
                                className={classes.formButtonClass}
                                disabled={isSubmitting}
                                {...bindOnClickOnKeyDown(() => handleOpenBookingModal(props))}
                                >
                                  {intl.locale === `en` ? <>Check<br />availability</> : <>Sprawdź<br />dostępność</>}
                              </Button>
                            </div>

                          </Grid>
                        </MuiPickersUtilsProvider>
                      }

                      {/* Kontakt w kwestiach technicznych - +48 888 116 514 */}
                      <Modal
                        id={`modal-booking-instance-${context}`}
                        open={propsIsModalBookingOpened}
                        onClose={handleCloseBookingModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                      >
                        <div id={`modal-booking-instance-${context}-container`}>
                          {/* <div className="d-flex flex-row"> */}
                              {/* <div className="spinner-grow" role="status">
                                <span className="sr-only">Loading...</span>
                              </div> */}

                              {/* <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary"
                                // className={classes.formButtonClass}
                                // disabled={isSubmitting}
                                {...bindOnClickOnKeyDown(() => handleCloseBookingModal())}
                                >
                                  {intl.locale === `en` ? <>Close</> : <>Zamknij</>}
                              </Button> */}

                              <iframe 
                                height="100%" 
                                width="100%" 
                                // src={`${BOOKING_URL}&checkin=${GeneralUtils.dateFormat(selectedDateArrival, `YYYY-MM-DD`)}&checkout=${GeneralUtils.dateFormat(selectedDateDeparture, `YYYY-MM-DD`)}`} 
                                // src={`https://panel.hotres.pl/widget3/index/mode/popup/style/add/lang/${intl.locale}/oid/2662/arrival/${GeneralUtils.dateFormat(selectedDateArrival, `YYYY-MM-DD`)}/departure/${GeneralUtils.dateFormat(selectedDateDeparture, `YYYY-MM-DD`)}/`} 
                                src={`https://panel.hotres.pl/widget3/index/oid/2662/lang/${intl.locale}/arrival/${GeneralUtils.dateFormat(selectedDateArrival, `YYYY-MM-DD`)}/departure/${GeneralUtils.dateFormat(selectedDateDeparture, `YYYY-MM-DD`)}/cnt_adult/${selectedAdult}`} 
                                frameBorder="0"></iframe>
                          {/* </div> */}
                        </div>

                      </Modal>      



                      {/* Form status messages */}
                      {/* <div className="row form-status">
                        <div className="col">
                          <div dangerouslySetInnerHTML={{ __html: formStatus.message }} className={formStatusClass} role="alert"></div>
                        </div>
                      </div> */}

                      {/* <DisplayFormikState {...props} /> */}
                      {/* <DisplayFormikState {...dateChangeOpenState} /> */}

                    </Form>
                  // </FixedBottom>
                );
              }}
            </Formik>
          }
        </MuiThemeProvider>
    )
}



const mapStateToProps = state => {

  // console.log(state)

  return {
    isModalBookingOpened: state.modal.isModalBookingOpened,
  }
}

// const mapDispatchToProps = (dispatch, ownProps) => ({
//     toggleModalBooking: () => dispatch(toggleModalBooking(!ownProps.isModalBookingOpened)),
// })
const mapDispatchToProps = dispatch => ({
  toggleModalBooking: isModalBookingOpened => dispatch(toggleModalBooking(!isModalBookingOpened)),
})

// export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FormStateless));
export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(FormStateless)
);